import React from 'react';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../imatges/noticia-4.jpg')} alt=""/>
                            <div className="blog_content">
                                <div className="post_date">
                                    <h2>24 <span>Mayo</span></h2>
                                </div>
                                <a href="/captacion-clientes">
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30">Cómo captar la atención</h5>
                                </a>
                                <p className="f_400 mb-30">Cómo se consigue atraer clientes con un entorno de saturación publicitaria? Captando su atención y facilitando que nos encuentren con el 'inbound marketing' y, en especial, con el marketing de contenidos (content marketing).</p>
                                <p className="f_400 mb_40">Vivimos en un entorno de excesos, tanto con lo que se refiere a la oferta mediática (multitud de canales de televisión, revistas y periódicos, libros para leer a nuestros dispositivos electrónicos o tabletas,...) como en nuestro entorno profesional (correos electrónicos, comentarios en las redes sociales, vídeos, webs...). Esta abundancia de información se le llama como 'infoxicación' o 'sobrecarga informativa'.<br/>
                                Y a los dos factores mencionados, además, tenemos que añadir un tercero, la saturación de publicidad en la que estamos expuestos a través de cientos de anuncios en los medios, marquesinas con publicidad exterior en cada esquina, llamadas telefónicas, etc ... Esta saturación cada vez es menos efectiva para el cliente que se rodea cada vez más con una especie de coraza contra estos ataques comerciales.
                                <br/></p>
                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">Por lo tanto, para tener éxito con los contenidos que generamos debemos:</h6>
                                </blockquote>
                                <p className="f_400 mb-30">1. Crear contenido atractivo <br/> 2. Saber, que el público al que va dirigido el contenido, quiera consumirlo</p>
                                <p className="f_400 mb-30">Si lo hacemos bien, incluso, querrán pagar por estos contenidos.</p>
                                <p className="f_400 mb-30">Pero como hacer contenido atractivo?</p>
                                <p class="f_400 mb-30">Ante un escenario saturado de contenidos y de canales de información, debemos estar seguros de que seremos capaces de captar la atención de nuestro público antes de poner en marcha alguna acción de marketing de contenidos. Pero, ¿qué tipo de contenido es capaz de captar esta atención? <br/>
                                Para preparar una estrategia de marketing de contenidos efectiva antes debemos hacernos muchas preguntas de las que dos marcarán el resto del proceso:</p>
                                <p class="f_400 mb-30">1. ¿Cuál es mi audiencia?<br/> 2. ¿Qué le puede interesar a mi audiencia? <br/><br/>
                                Tenemos que crear un buen contenido, ponernos en el lugar del cliente y pensar cómo nos gustaría que nos tratara una empresa como la nuestra, debemos intentar ir siempre un paso por delante de los consumidores porque como decía Steve Jobs: "Puedes preguntar a tus clientes que quieren y tratar de hacerlo, pero mientras lo estás preparando, ellos ya querrán otra cosa".</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;
