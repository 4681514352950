import React from 'react';
import BlogGridItem from './BlogGridItem';
const BlogGrid =()=>{
    return(
        <section className="blog_area_two sec_pad">
            <div className="container">
                <h2 className="f_600 f_size_30 t_color3 text-center l_height40 wow fadeInUp" data-wow-delay="0.3s">Últimos artículos de interés</h2>
                <div className="row">
                    <div className="col-lg-12 blog_grid_info">
                        <div className="row fadeInUp" data-wow-delay="0.5s">
                            <BlogGridItem date="8" month="Julio" image="noticia-2.jpg" Title="Departamento de marketing" description="¿Su empresa lo necesita realmente? Y si es así, nos tenemos que centrar únicamente con la 'P' de prommotion o con otras 'P' como product, price i place."
                            btn="LEER MÁS" url="departamento-marqueting" comment="Marketing"/>
                            <BlogGridItem date="14" month="Junio" image="noticia-8.jpg" Title="Fidelización de clientes" description="Dos de las cuestiones más importantes para el éxito de una empresa y/o red comercial son los clientes fieles y unas propuestas de valor sólidas."
                            btn="LEER MÁS" url="fidelizacion-clientes" comment="Fidelización"/>
                            <BlogGridItem date="7" month="Junio" image="noticia-1.jpg" Title="Gestión de procesos y optimización" description="La excelencia, bien gestionada e implementada, nos asegura la permanencia en la calidad y una mentalidad de mejora continua."
                            btn="LEER MÁS" url="gestion-procesos-optimizacion" comment="Optimización"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogGrid;
