import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import CloudBanner from '../components/Banner/CloudBanner';
import CloudService from '../components/Service/CloudService';
import HostingAction from '../components/HostingAction';
import Frase from '../components/Frase';
import Bloglists from '../components/Blog/Bloglists';
import DeveloperTab from '../components/DeveloperTab';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const HomeCloud = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <CloudBanner/>
            <CloudService/>
            <HostingAction/>
            <Frase/>
            <Bloglists/>
            <DeveloperTab/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default HomeCloud;