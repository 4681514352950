import React , {Component} from 'react';
import AboutWidget from './FooterWidget/AboutWidget';
import SolutionWidget from './FooterWidget/SolutionWidget';
import TeamWidget from './FooterWidget/TeamWidget';
import Reveal from 'react-reveal/Reveal'
class FooterTwo extends Component {
    render(){
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return(
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            <AboutWidget ftitle="Menú"  FooterData={FooterData}/>
                            <SolutionWidget ftitle="Os Recomiendo" FooterData={FooterData}/>
                            <TeamWidget ftitle="Redes Sociales" FooterData={FooterData}/>
                            {
                                FooterData.CompanyWidget.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInUp" key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                <p>Enamorado de las nuevas tecnologías.</p>
                                                <a href="index.html" className="f-logo"><img src={require('../../imatges/tecnologia.svg')} alt=""/></a>
                                                    <div className="widget-wrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-5 col-sm-6">
                                <p className="mb-0 f_400">{FooterData.copywrite}</p>
                            </div>
                            
                            <div className="col-lg-6 col-md-4 col-sm-12">
                                <ul className="list-unstyled f_menu text-right">
                                    <li><a href=".#">Analítica Web</a></li>
                                    <li><a href=".#">Metodologías Ágiles</a></li>
                                    <li><a href=".#">Programación Web</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;
