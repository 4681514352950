import React from 'react';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../imatges/noticia-8.jpg')} alt=""/>
                            <div className="blog_content">
                                <div className="post_date">
                                    <h2>14 <span>Junio</span></h2>
                                </div>
                                <a href="/fidelizacion-clientes">
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30">La importancia de la fidelización de los clientes</h5>
                                </a>
                                <p className="f_400 mb-30">Dos de las cuestiones más importantes para el éxito de una empresa y/o red comercial son los clientes fieles y unas propuestas de valor sólidas. Éstas, además, están totalmente interconectadas, ya que la evidencia empírica indica que la combinación adecuada de ambas es la mejor manera de fidelizar a los clientes.</p>
                                <p className="f_400 mb_40">Pero para que las empresas quieren clientes fieles? Las respuestas son sencillas , y una de ellas es financiera. Según los estudios de Frederick Reichheld, consultor en Bain & Co., unos incrementos de un 5% con fidelidad de clientes se pueden convertir en aumentos de los beneficios para las empresas entre un 25% y un 100%.</p>
                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">Además, las compañías con un gran número de clientes fieles pueden llegar a superar con más del doble el crecimiento hacia sus competidores directos.</h6>
                                </blockquote>
                                <p className="f_400 mb-30">Las estadísticas confirman que la probabilidad de venta a un cliente activo es de entre el 60 % y el 70 %, frente a porcentajes de entre un 20% y un 40% para clientes ‘perdidos’ o ‘ex clientes’ y sólo entre un 5% y un 20% para clientes nuevos.</p>
                                <p className="f_400 mb-30">La fidelización de un cliente podemos empezar a notar cuando los clientes, además de tenerlos activos dentro de la empresa, nos recomienda o hace comentarios favorables hacia nosotros en los foros sectoriales, entre amigos y/o en las redes sociales. Está claro, que más allá de estos comentarios, cuando el cliente rechaza otras ofertas de la competencia o incluso un presupuesto más bajo, entonces podemos decir que ya tenemos el cliente fidelizado.</p>
                                <p className="f_400 mb-30">Ahora sin embargo, hará falta no dejar de atenderle correctamente.</p>
                                <p class="f_400 mb-30">Tradicionalmente, desde el Departamento de Ventas siempre se ha considerado que la fidelización pasaba por una buena relación personal pero hoy en día, en unos momentos en que los clientes se enfrentan a entornos muy exigentes, las relaciones personales no es el único que el cliente busca para mantenerse lealmente a una empresa.</p>
                                <p class="f_400 mb-30">Como lo hacemos entonces?, La respuesta es fácil, los clientes se quedan porque les damos un buen servicio.</p>
                                <p class="f_400 mb-30">No se trata de construir una fidelización de una manera transaccional o en una negociación, en la que las variables precio y relación personal pueden suponer ventajas, sino de construir una fidelización diaria y una vez seamos capaces de solucionar día a día los problemas de los clientes, podremos conseguir su fidelización.</p>
                                <p class="f_400 mb">La idea de solucionar los problemas del cliente, de darle un buen servicio constantemente, se resume en el concepto de ofrecerle valor, que como espero que recuerde, al principio del artículo ya hemos comentado que su combinación es el buen resultado.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;