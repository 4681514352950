import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ChatIntegration from "../components/ChatIntegration";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Service = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" Ptitle="Entre todos los proyectos que ha emprendido el ser humano, la aventura de la ciudadanía ha sido la más arriesgada y la más sorprendente." />
            <ChatIntegration />
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Service;