import React, {Component} from 'react';

class DeveloperTab extends Component {
    render(){
        return(
            <section className="developer_product_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="developer_product_content">
                                <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50 wow fadeInUp" data-wow-delay="0.2s">Si se puede imaginar... <br></br>se puede programar</h2>
                                <ul className="nav nav-tabs develor_tab mb-30" id="myTab2" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active"  data-tab="tab_one" id="php-tab" data-toggle="tab" href="#php" role="tab" aria-controls="php" aria-selected="true">php </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-tab="tab_two" id="mysql-tab" data-toggle="tab" href="#mysql" role="tab" aria-controls="mysql" aria-selected="false"> mysql</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-tab="tab_three" data-toggle="tab" href="#js" role="tab" aria-controls="js" aria-selected="true">js</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-tab="tab_four" id="react-tab" data-toggle="tab" href="#react" role="tab" aria-controls="react" aria-selected="false">reactJS</a>
                                    </li>
                                </ul>
                                <div className="tab-content developer_tab_content">
                                    <div className="tab-pane fade show active" id="php" role="tabpanel" aria-labelledby="php-tab">
                                        <p className="mb_40">PHP, acrónimo recursivo en inglés de PHP: Hypertext Preprocessor (preprocesador de hipertexto), es un lenguaje de programación de propósito general de código del lado del servidor originalmente diseñado para el preprocesado de texto plano en UTF-8. Lerdorf diseñó la primera versión de PHP en lenguaje Perl basado en la escritura de un conjunto de CGI del lenguaje C.</p>
                                        <a href="https://www.php.net" target="_blank" rel="noopener noreferrer" className="details_btn">Documentación</a>
                                    </div>
                                    <div className="tab-pane fade" id="mysql" role="tabpanel" aria-labelledby="mysql-tab">
                                        <p className="mb_40">MySQL es un sistema de gestión de bases de datos relacional desarrollado bajo licencia dual: Licencia pública general/Licencia comercial por Oracle Corporation y está considerada como la base de datos de código abierto más popular del mundo,1​2​ y una de las más populares en general junto a Oracle y Microsoft SQL Server, sobre todo para entornos de desarrollo web.</p>
                                        <a href="https://www.mysql.com" rel="noopener noreferrer" className="details_btn" target="_blank">Documentación</a>
                                    </div>
                                    <div className="tab-pane fade" id="js" role="tabpanel" aria-labelledby="js-tab">
                                        <p className="mb_40">JavaScript (abreviado comúnmente JS) es un lenguaje de programación interpretado, dialecto del estándar ECMAScript. Se define como orientado a objetos,3​ basado en prototipos, imperativo, débilmente tipado y dinámico. Se utiliza principalmente en su forma del lado del cliente (client-side), implementado como parte de un navegador web permitiendo mejoras en la interfaz de usuario y páginas web dinámicas4​ y JavaScript del lado del servidor (Server-side JavaScript o SSJS).</p>
                                        <a href="https://www.javascript.com" rel="noopener noreferrer" className="details_btn" target="_blank">Documentación</a>
                                    </div>
                                    <div className="tab-pane fade" id="react" role="tabpanel" aria-labelledby="react-tab">
                                        <p className="mb_40">ReactJS es una biblioteca Javascript de código abierto diseñada para crear interfaces de usuario con el objetivo de facilitar el desarrollo de aplicaciones en una sola página. Es mantenido por Facebook y la comunidad de software libre. Han participado en el proyecto más de mil desarrolladores diferentes. React intenta ayudar a los desarrolladores a construir aplicaciones que usan datos que cambian todo el tiempo. Su objetivo es ser sencillo, declarativo y fácil de combinar.</p>
                                        <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer" className="details_btn">Documentación</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tab_img_info">
                                <div className="tab_img active" id="tab_one">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../imatges/code5.svg")} alt=""/>
                                </div>
                                <div className="tab_img" id="tab_two">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../imatges/code2.svg")} alt=""/>
                                </div>
                                <div className="tab_img" id="tab_three">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../imatges/code1.svg")} alt=""/>
                                </div>
                                <div className="tab_img" id="tab_four">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../imatges/code.svg")} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default DeveloperTab;