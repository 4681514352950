import React from 'react';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../imatges/noticia-2.jpg')} alt=""/>
                            <div className="blog_content">
                                <div className="post_date">
                                    <h2>8 <span>Julio</span></h2>
                                </div>
                                <a href="/fidelizacion-clientes">
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30">¿Su empresa necesita realmente un departamento de marketing?</h5>
                                </a>
                                <p className="f_400 mb-30">Y si es así, nos tenemos que centrar únicamente con la 'P' de prommotion o con otras 'P' como product, price y place.</p>
                                <p className="f_400 mb_40">Muchos CEO's de empresas se preguntan y reflexionan sobre estas cuestiones, si las funciones que se realizan son las correctas, si disponen de la dimensión adecuada, si se debería ampliar, reducir o incluso eliminar.</p>
                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">A principios de los 90 sabemos que las empresas empezaron a cambiar el nombre del vicepresidente de marketing por el de director ejecutivo de marketing y fué más que un cambio de palabras.</h6>
                                </blockquote>
                                <p className="f_400 mb-30">Pues el marketing pasó a estar en manos de un director ejecutivo que se unía a otros directores ejecutivos como el director general, financiero, de comunicación, etc.<br />Actualmente, hay unos 3.000 directores de marketing (CMO) en Estados Unidos, pocos países han nombrado un CMO en su empresa.</p>
                                <p><strong>Oficialmente los CMO son responsables de 6 tareas:</strong></p>
                                <p className="f_400 mb-30">1.- Monitorizar el ámbito del consumidor, representar la voz del cliente y liderar un desarrollo con una fuerte orientación hacia el cliente para conseguir su retención y fidelización. <br /> 2.- Recopilar información sobre el punto de vista del cliente para ayudar a desarrollar nuevos productos y servicios y conseguir unos objetivos crecientes.<br />3.- Ocuparse de la imagen corporativa y de la marca.<br />4.- Renovar la tecnología de marketing y las habilidades y capacidades de la empresa.<br />5.- Contabilizar los resultados financieros de las actividades de marketing, campañas, etc.<br />6.- Aportar conocimientos y establecer sinergias.</p>
                                <p className="f_400 mb-30">Algunas empresas consideran que el marketing es demasiado importante para dejarlo únicamente en manos del departamento de marketing y creen que debe ser responsabilidad de todos los integrantes de la empresa, eso si, un CMO debe centrarse como mínimo con algunas de las seis actividades que he mencionado anteriormente.<br />Aunque realmente algunas empresas saben que tienen que añadir a su departamento de marketing expertos en las redes sociales y marketing online.</p>
                                <p className="f_400 mb-30">Desde hace medio año aproximadamente, <strong>Dominique Turpin</strong>, presidente de la IMD Business School en suiza, entierra el CMO y hace aparecer la nueva figura CCO (director de clientes) que será el encargado de conocer a los clientes, saber lo que quieren y hacia donde quieren ir.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;