import React, { Component } from 'react';
import CloudServiceItem from './CloudServiceItem';

class CloudService extends Component{
    render(){
        return(
            <section className="software_featured_area">
                <div className="container">
                    <h2 className="f_600 f_size_30 t_color3 text-center l_height40 mb_70 wow fadeInUp" data-wow-delay="0.6s">La innovación es lo que distingue a un lider de los demás</h2>
                    <div className="row software_featured_info">
                        <CloudServiceItem icon="innovacion.svg" sTitle="Innovación" Sp="Innovación es un cambio que introduce novedades. Además, en el uso coloquial y general, el concepto se utiliza de manera específica en el sentido de nuevas propuestas, inventos y su implementación económica. En el sentido estricto, en cambio, se dice que de las ideas solo pueden resultar innovaciones luego de que ellas se implementan como nuevos productos, servicios o procedimientos, que realmente encuentran una aplicación exitosa, imponiéndose en el mercado a través de la difusión."/>
                        <CloudServiceItem icon="liderazgo-2.svg" sTitle="Liderazgo" Sp="El liderazgo es el conjunto de habilidades gerenciales o directivas que un individuo tiene para influir en la forma de ser de las personas o en un grupo de personas determinado, haciendo que este equipo trabaje con entusiasmo, en el logro de metas y objetivos.También se entiende como la capacidad de tomar la iniciativa, gestionar, convocar, promover, incentivar, motivar y evaluar un proyecto, de forma eficaz y eficiente, sea éste personal, gerencial o institucional."/>
                    </div>
                </div>
            </section>
        )
    }
}
export default CloudService;