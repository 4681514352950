import React from 'react';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../imatges/noticia-8.jpg')} alt=""/>
                            <div className="blog_content">
                                <div className="post_date">
                                    <h2>31 <span>Mayo</span></h2>
                                </div>
                                <a href="/analitica-web-google-analytics">
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30">Analítica Web con Google Analytics</h5>
                                </a>
                                <p className="f_400 mb-30">Siempre es un placer hablar de la analítica web, uno de los campos que más me apasionan y con más interés actualmente entre muchos desarrolladores del mundo web.</p>
                                <p className="f_400 mb_40">Para todos aquellos que seáis un poco nuevos en este apasionado mundo de la analitica digital, os la voy a definir con una gran frase de uno de los referentes de la analítica digital, “Avinash Kaushik“. Y la define como “El análisis de los datos cualitativos y cuantitativos del negocio y la competencia para impulsar ua mejora continua de la experiencia online que tienen tus clientes y potenciales clientes para que se traduzca en alcanzar los resultados deseados (on-line y off-line).”</p>
                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">Pero no os austéis porque la analítica no va de tener muchos datos, sino lo que realmente importa, tener muchos ‘insights‘ y para ello, necesitamos tener unos objetivos muy claros.</h6>
                                </blockquote>
                                <p className="f_400 mb-30">Una de las facilidades que nos trae el Panel de Google Analytics son los imprescindibles Dashobards, que nos van a servir para detectar cosas que llaman la atención o para hacerse preguntas. Es importante recordar que el Dashboard está para controlar la marcha global del negocio, NO para ver los detalles.</p>
                                <p className="f_400 mb-30">Otra de las piezas claves de la analítica es la atribución, es decir, asignarle un valor económico a una conversión. De esta forma, podemos conocer el valor económico logrado por las conversiones de cada canal de marketing, y se puede controlar/analizar el retorno de nuestra inversión en captación.</p>
                                <p className="f_400 mb-30">Con la herramienta de Google Analytics podremos realizar la medición de la interacción de los usuarios con nuestros distintos entornos digitales e incluso físicos.</p>
                                <p class="f_400 mb">Puedes contactar conmigo como freelance certificado por Google Analytics y Google Partner, te puedo llevar la analítica de tu web y diseñar conjuntamente tu estrategia web.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;