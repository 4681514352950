import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Fidelizacionclientes from '../components/Blog/Fidelizacionclientes'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const FidelizacionClientes = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" Ptitle="La importancia de la fidelización de los clientes" Pdescription="Dos de las cuestiones más importantes para el éxito de una empresa y/o red comercial son los clientes fieles y unas propuestas de valor sólidas."/>
            <Fidelizacionclientes/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default FidelizacionClientes;