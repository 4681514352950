import React from 'react';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../imatges/noticia-1.jpg')} alt=""/>
                            <div className="blog_content">
                                <div className="post_date">
                                    <h2>7 <span>Junio</span></h2>
                                </div>
                                <a href="/gestion-procesos-optimizacion">
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30">Gestión de procesos y optimización</h5>
                                </a>
                                <p className="f_400 mb-30">La excelencia, bien gestionada e implementada, nos asegura la permanencia en la calidad y una mentalidad de mejora continua</p>
                                <p className="f_400 mb_40">Procesos y tiempo son dos factores claves en las organizaciones. De hecho, los dos están directamente y proporcionalmente relacionados. Sin duda, con una mejor organización de procesos, disfrutaremos de más tiempo para dedicarlo a otras tareas.</p>
                                <blockquote className="blockquote mb_40">
                                    <h6 className="mb-0 f_size_18 l_height30 f_p f_400">La correcta gestión de estos dos factores hace que el profesional consiga ser más productivo y competitivo, en resumen, que se convierta con alguien realmente eficaz en el entorno empresarial.</h6>
                                </blockquote>
                                <p className="f_400 mb-30">Por definir, cuando hablamos de procesos hablamos de la “estandarización” que es básica para la mejora continua, de la obtención de la calidad y de la autonomía de los trabajadores. No podemos conseguir los mejores productos o servicios sin los procedimientos que aseguren la constancia en el proceso, lo que implica la involucración del trabajador.</p>
                                <p className="f_400 mb-30">Y la pregunta es: ¿Cómo se deben implementar los procesos?</p>
                                <p className="f_400 mb-30">Debemos destacar que los procesos son un sistema de ayuda, por lo que deben ser sencillos y prácticos, para que puedan incorporarse al trabajador diario</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;