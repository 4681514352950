import React, {Component} from 'react';

class Frase extends Component {
    render(){
        return(
            <section className="action_area_two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="action_content">
                            <h2 className="f_600 f_size_30 l_height45 t_color3 mb-0 wow fadeInLeft" data-wow-delay="0.5s">Reunir el equipo de trabajo adecuado es requisito necesario para tener las ideas claras.</h2>
                            <p className="mb_40">– EDWIN CATMULL</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="action_img wow fadeInRight" data-wow-delay="0.5s">
                            <img className="img-fluid" src={require ("../imatges/equip-jordi-miro.svg")} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Frase;
