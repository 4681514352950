const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Contacta",
            description: "Contacta sin compromiso y hablemos de tus proyectos!",
        }
    ],
    AboutWidget: [
        {
          id: 1,
          title: "Download",
          menuItems: [
              {
                id: 1,
                url: '#',
                text: 'Publicaciones',
              },
              {
                id: 2,
                url: '#',
                text: 'Android App',
              },
              {
                id: 3,
                url: '#',
                text: 'ios App',
              },
              {
                id: 4,
                url: '#',
                text: 'Desktop',
              },
              {
                id: 5,
                url: '#',
                text: 'Projects',
              },
              {
                  id: 6,
                  url: '#',
                  text: 'My tasks',
              },
          ],
      },
      {
            id: 2,
            title: "Help",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  text: 'FAQ',
                },
                {
                  id: 2,
                  url: '#',
                  text: 'Term & conditions',
                },
                {
                  id: 3,
                  url: '#',
                  text: 'Reporting',
                },
                {
                  id: 4,
                  url: '#',
                  text: 'Documentation',
                },
                {
                  id: 5,
                  url: '#',
                  text: 'Support Policy',
                },
                {
                    id: 6,
                    url: '#',
                    text: 'Privacy',
                  },
            ],
        },
    ],
    termsCondition: [
      {
          id: 1,
          title: "Team Solutions",
          menuItems: [
            {
              id: 1,
              url: '#',
              text: 'Support',
            },
            {
              id: 2,
              url: '#',
              text: 'Sales',
            },
            {
              id: 3,
              url: '#',
              text: 'Report Abuse',
            },
            {
              id: 4,
              url: '#',
              text: 'System Status',
            }
        ],
      }
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  icon: 'ti-facebook',
                },
                {
                  id: 2,
                  url: '#',
                  icon: 'ti-twitter-alt',
                },
                {
                  id: 3,
                  url: '#',
                  icon: 'ti-vimeo-alt',
                },
                {
                  id: 4,
                  url: '#',
                  icon: 'ti-pinterest',
                }
            ]
        }
    ],
    socialIcon:[
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'ti-facebook',
      },
      {
        id: 2,
        url: '#',
        icon: 'ti-twitter-alt',
      },
      {
        id: 3,
        url: '#',
        icon: 'ti-vimeo-alt',
      },
      {
        id: 4,
        url: '#',
        icon: 'ti-pinterest',
      }
    ],
    copywrite:"Copyright © 2020",
    TestimonialText: [
      {
        id: 1,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 2,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 3,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 4,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      }
    ],
    aboutLinks: [
      {
        id: 1,
        url: '#',
        item: 'Developer',
      },
      {
        id: 2,
        url: '#',
        item: 'Blog',
      },
      {
        id: 3,
        url: '#',
        item: 'Investor',
      },
      {
        id: 4,
        url: '#',
        item: 'Sitemap',
      },
      {
        id: 5,
        url: '#',
        item: 'Jobs',
      }
    ],
    helpLinks: [
      {
        id: 1,
        url: '#',
        item: 'Help and Contact',
      },
      {
        id: 2,
        url: '#',
        item: 'Fees',
      },
      {
        id: 3,
        url: '#',
        item: 'Security',
      },
      {
        id: 4,
        url: '#',
        item: 'App',
      },
      {
        id: 5,
        url: '#',
        item: 'Shop',
      }
    ],
    PrivacyLinks: [
      {
        id: 1,
        url: '#',
        item: 'Privacy Policy',
      },
      {
        id: 2,
        url: '#',
        item: 'Legal Agreement',
      },
      {
        id: 3,
        url: '#',
        item: 'Feedback',
      }
    ],
    about: [
      {
        id: 1,
        url: '/blog',
        text: 'Publicaciones',
      },
      {
        id: 2,
        url: '/proyectos',
        text: 'Proyectos',
      },
      {
        id: 3,
        url: '/jordi-miro',
        text: 'Sobre mi',
      },
      {
        id: 4,
        url: '/contacta',
        text: 'Contacta',
      },
    ],
    Solution: [
      {
        id: 1,
        url: 'https://www.php.net',
        text: 'php',
      },
      {
        id: 2,
        url: 'https://www.mysql.com',
        text: 'mysql',
      },
      {
        id: 3,
        url: 'https://www.javascript.com',
        text: 'js',
      },
      {
        id: 4,
        url: 'https://reactjs.org',
        text: 'ReactJS',
      },
    ],
    teamSolution: [
      {
        id: 1,
        url: '#',
        text: 'Instagram',
      },
      {
        id: 2,
        url: '#',
        text: 'Linkedin',
      },
      {
        id: 3,
        url: '#',
        text: 'Spotify',
      },
      {
        id: 4,
        url: '#',
        text: 'Twitter',
      },
    ],
};
export default FooterData;