import React, {Component} from 'react';

class HostingAction extends Component {
    render(){
        return(
            <section className="saas_featured_area sec_pad dk_bg_one">
                <div className="square_box box_three"></div>
                <div className="square_box box_four"></div>
                <div className="container">
                    <div className="row saas_featured_info flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="h_action_img f_img_two img_border wow fadeInRight">
                                <img className="img-fluid" src={require ("../imatges/scrum.svg")} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center pr_70">
                            <div className="saas_featured_content wow fadeInLeft">
                                <h2 className="f_size_30 f_600 f_p w_color l_height40 mb_20">Apasionado de las metodologías ágiles y las nuevas tecnologías</h2>
                                <p>El desarrollo ágil de software envuelve un enfoque para la toma de decisiones en los proyectos de software, que se refiere a métodos de ingeniería del software basados en el desarrollo iterativo e incremental, donde los requisitos y soluciones evolucionan con el tiempo según la necesidad del proyecto. Así el trabajo es realizado mediante la colaboración de equipos autoorganizados y multidisciplinarios, inmersos en un proceso compartido de toma de decisiones a corto plazo</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HostingAction;
