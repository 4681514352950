import React from 'react';
const ChatIntegration =()=>{
    return(
        <section className="security_integration_area chat_integration_area">
            <div className="container">
                <div className="chat_title text-center">
                    <h2 className="wow fadeInUp">Implicado totalmente en el tejido <span>asociativo.</span></h2>
                    <p>La diversidad de asociaciones (asociaciones de vecinos, de jóvenes, de mujeres, gente mayor, inmigrantes, deportivas, culturles, profesionales, sindicatos, comerciantes, consumidores,... ) es la expresión de la riqueza y la pluralidad de la sociedad.
Porque el tejido asociativo es el motor social de cualquier sociedad.</p>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <span className="s_integration_item">
                            <img src={require("../imatges/mollerussa-lanparty.jpg")} width="200px" alt="Mollerussa Lan Party"/>
                            <h5>Mollerussa Lan Pary</h5>
                            <p>Co-Founder y Presidente</p>
                            <p>2008-2010</p>
                        </span>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <span className="s_integration_item">
                            <img src={require("../imatges/pbplaurgell.jpg")} width="150px" alt="PB Pla Urgell"/>
                            <h5>PB Pla Urgell</h5>
                            <p>Co-Founder y Presidente</p>
                            <p>2008-2012</p>
                        </span>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <span className="s_integration_item">
                            <img src={require("../imatges/neptunesapp.png")} width="300px" alt="Neptunes App"/>
                            <h5>Neptunes App</h5>
                            <p>Co-Founder y CEO</p>
                            <p>2014</p>
                        </span>
                    </div>
                    
                    
                </div>
            </div>
        </section>
    )
}
export default ChatIntegration;