import React from 'react';

const ServiceDetails =()=> {
    return(
        <section className="job_details_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">Jordi Miró</h6>
                            </div>
                            <div className="info_item">
                                <i className="ti-ruler-pencil"></i>
                                <h6>Departamento</h6>
                                <p>IT Departments</p>
                            </div>
                            <div className="info_item">
                                <i className="ti-location-pin"></i>
                                <h6>Localización</h6>
                                <p>Barcelona</p>
                            </div>
                            <div className="info_item">
                                <i className="ti-layout-tab-window"></i>
                                <h6>Cargo</h6>
                                <p>CTO</p>
                                <p>Scrum Master</p>
                                <p>Developer</p>
                                <p>Consultor</p>
                            </div>
                            <div className="info_item">
                                <i className="ti-pencil-alt"></i>
                                <h6>Calificaciones</h6>
                                <p>Diplomatura</p>
                                <p>Grado</p>
                                <p>Certificaciones</p>
                            </div>
                            <div className="info_item">
                                <i className="ti-cup"></i>
                                <h6>Experiencia</h6>
                                <p>+20 años en el sector tecnológico</p>
                                <p>+3 años en el sector educativo</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl_70">
                        <div className="details_content">
                            <div className="job_deatails_content">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Experiencia</h3>
                                <ul className="list-unstyled mb-0">
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">CTO & Director General</span> en BedLoop. Creación de un software para alquiler vacacional y hoteles. Plataforma que incluye un Channel Managaer (Conexión directas con Airbnb, Booking, HomeAway,...), PMS, CMS, CRM y ERP.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">CTO</span> en IndianWebs. Oficina Central. Creación, análisis y programación de proyectos web.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Project Manager</span> en IndianWebs. Oficina Lesseps. Análisis y programación de proyectos web.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Programador</span> en IndianWebs. Oficina Lesseps. Programador web para creción de proyectos web.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Concejal</span> de Nuevas Tecnologías. Ayuntamiento de Mollerussa.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Jefe de Estudios</span> del departamento informático. Fundació CIC. Preparación y Coordinación del material y contenido a desarrollar para el curso.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Profesor</span> de Informática. Instituto y Primaria. Impartir clases relacionadas con las nuevas tecnologías.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Administrador de Sistemas</span>. Digit. Administración de servidores para empresas.</li>
                                </ul>
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Formación</h3>
                                <ul className="list-unstyled mb-0">
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Máster en Marketing Digital y Comercio Electrónico (e-commerce)</span>. Universitat Autònoma de Barcelona y EAE Business School.</li>
                                    <li><i className="ti-arrow-right"></i>Certificado en <span className="experiencia">Expert Scrum Master</span>. European Scrum Foundations.</li>
                                    <li><i className="ti-arrow-right"></i>Certificado en <span className="experiencia">Expert Agile Projects</span>. European Scrum Foundations.</li>
                                    <li><i className="ti-arrow-right"></i>Certificado en <span className="experiencia">Google Analytics</span>. Google.</li>
                                    <li><i className="ti-arrow-right"></i>Certificado en <span className="experiencia">HootSuite</span> Social Media. HootSuite University.</li>
                                    <li><i className="ti-arrow-right"></i>Certificado en <span className="experiencia">Google Adwords</span>. Google.</li>
                                    <li><i className="ti-arrow-right"></i>Certificado como <span className="experiencia">desarrollador Prestashop</span>. Prestashop.</li>
                                    <li><i className="ti-arrow-right"></i><span className="experiencia">Diplomatura de Magisterio</span>. Universitat de Lleida y Universitat Rovira Virgili.</li>
                                    <li><i className="ti-arrow-right"></i>Grado Superior en <span className="experiencia">Administración de Sistemas</span>. Les Heures.</li>
                                    <li><i className="ti-arrow-right"></i>Grado en <span className="experiencia">Administración y Dirección de Empresas</span>. La Salle.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ServiceDetails;