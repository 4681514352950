import React, {Component} from 'react';

class HostingFeaturesItem extends Component {
    render(){
        let {rowClass, hImage, hTitle, hPragraph, tdescription, tdescription2, tdescription3}= this.props;
        return(
            <div className={`row ${rowClass}`}>
                <div className="col-lg-6">
                    <div className="h_features_img">
                        <img src={require ("../../imatges/" + hImage)} width="600px" alt=""/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="service_details">
                        <div className="hosting_title">
                            <h2>{hTitle}</h2>
                            <p className="f_400 mb_50">{hPragraph}</p>
                        </div>
                         <ul className="list-unstyled mb-30 pl-0 pr_20">
                                <li>
                                <i className="ti-check"></i> {tdescription}
                                </li>
                                <li>
                                <i className="ti-check"></i> {tdescription2}
                                </li>
                                <li>
                                <i className="ti-check"></i> {tdescription3} 
                                </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default HostingFeaturesItem;