import React from 'react';
import HostingFeaturesItem from './HostingFeaturesItem';

const HostingFeaturesTwo =()=>{
    return(
        <section className="service_details_area sec_pad">
            <div className="container">
                <HostingFeaturesItem rowClass="flex-row-reverse" hImage="sobre-jordi-miro.svg" hTitle="Implementación de metodologías ágiles." hPragraph="Fanático del orden y la planificación. Me encanta aplicar metodologías ágiles a las empresas, aplicar unos protocolos y metodología a seguir para que todo el departamento IT pueda desarrollar más fácilmente sus tareas."  tdescription="Estudio y Análisis de las necesidades de la empresa." tdescription2="Implementación de la metodología." tdescription3="Aplicación de los protocolos a seguir."/>
            </div>
        </section>
    )
}

export default HostingFeaturesTwo;