import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
class BlogGridItem extends Component{
    render(){
        let{date, month, Title, description, btn, url, comment, image}=this.props;
        return(
            <Reveal effect="fadeInUp">
                <div className="col-lg-4">
                    <div className="blog_list_item blog_list_item_two">
                        <a href={`${url}`} className="post_date">
                            <h2>{date} <span>{month}</span></h2>
                        </a>
                        <img className="img-fluid" src={require ("../../imatges/" + image)}alt=""/>
                        <div className="blog_content">
                            <a href={`${url}`}>
                                <h5 className="blog_title">{Title}</h5>
                            </a>
                            <p>{description}</p>
                            <div className="post-info-bottom">
                                <a href={`${url}`} className="learn_btn_two">{btn} <i className="arrow_right"></i></a>
                                <a className="post-info-comments" href={`${url}`}>
                                    <i className="icon_comment_alt" aria-hidden="true"></i>
                                    <span>{comment} </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Reveal>
        )
    }
}
export default BlogGridItem;