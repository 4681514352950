import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Captacionclientes from '../components/Blog/Captacionclientes'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const CaptacionClientes = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" Ptitle="Cómo captar la atención" Pdescription="Cómo se consigue atraer clientes con un entorno de saturación publicitaria?"/>
            <Captacionclientes/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default CaptacionClientes;