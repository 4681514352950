import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

/*------ Pages-----*/
import ScrollToTopRoute from "./ScrollToTopRoute";
import About from "./Pages/About";
import Service from "./Pages/Service";
import BlogSingle from "./Pages/BlogSingle";
import AnaliticaWeb from "./Pages/AnaliticaWeb";
import CaptacionClientes from "./Pages/CaptacionClientes";
import FidelizacionClientes from "./Pages/FidelizacionClientes";
import DepartamentoMarqueting from "./Pages/DepartamentoMarqueting";
import Contact from "./Pages/Contact";
import HomeCloud from "./Pages/Home-cloud";
import BlogGridPage from "./Pages/BlogGridPage";
import Bloglist from "./Pages/Bloglist";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={HomeCloud} />
          <ScrollToTopRoute path="/jordi-miro" component={About} />
          <ScrollToTopRoute path="/proyectos" component={Service} />
          <ScrollToTopRoute path="/blog" component={BlogGridPage} />
          <ScrollToTopRoute path="/Contacta" component={Contact} />
          <ScrollToTopRoute path="/Bloglist" component={Bloglist} />
          <ScrollToTopRoute path="/gestion-procesos-optimizacion" component={BlogSingle} />
          <ScrollToTopRoute path="/analitica-web-google-analytics" component={AnaliticaWeb} />
          <ScrollToTopRoute path="/captacion-clientes" component={CaptacionClientes} />
          <ScrollToTopRoute path="/fidelizacion-clientes" component={FidelizacionClientes} />
          <ScrollToTopRoute path="/departamento-marqueting" component={DepartamentoMarqueting} />
        </Switch>
      </Router>
    );
  }
}

export default App;
